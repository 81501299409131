<template>
  <div class="notice">
    <div class="wrapper">
      <div class="title">NOTICE</div>
      <div class="message">
        iOS15 사용자는 애플 자체 오류로 인하여<br />
        동영상 화면이 재생되지 않는 경우가 발생하고 있습니다.<br />
        아래의 순서에 따라 세팅을 변경하시면<br />
        정상적인 콘텐츠 체험이 가능합니다.
      </div>
      <div class="image">
        <img src="../assets/notice_box.png" alt="" />
      </div>
      <div class="button" @click="goPano2VR()">
        <span>확인</span>
      </div>
    </div>
    <div class="bottom">
      <img
        class="tom-greyhound"
        src="../assets/re_main_logo_tomgreyhound.png"
        alt="Tom Greyhound"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getLink() {
      const isAndroidWebView = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(
        navigator.userAgent
      );
      const url = new URL(window.location.href);
      const protocol = url.protocol.indexOf("https:") !== -1 ? "https" : "http";
      if (isAndroidWebView) {
        return `intent://${url.host}/pano#Intent;scheme=${protocol};package=com.android.chrome;end`;
      }
      if (url.host.indexOf("localhost") === 0 || url.host.indexOf("192.168.") === 0) {
        return "https://tomstyle21winter.fassker.net/pano";
      }
      return `${protocol}://${url.host}/pano`;
    },
    goPano2VR() {
      window.location.href = this.getLink();
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  overflow: hidden;
  line-height: 1;
  height: 100%;
  z-index: 3;
  background-color: #f5f5f5;
  .wrapper {
    height: 352px;
    top: calc(50% - (352px / 2));
  }
  .title {
    font-family: "GothamSSm";
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.266px;
  }
  .message {
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 20px;
    margin-top: 8px;
  }
  .image {
    margin-top: 20px;
    img {
      width: 242px;
    }
  }
  .button {
    margin: 0 auto;
    margin-top: 28px;
    width: 242px;
    height: 36px;
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #fff;
    span {
      display: inline;
      vertical-align: middle;
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    bottom: 26px;
    .tom-greyhound {
      width: 113.2px;
    }
  }
}
</style>
